import { useEffect } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { getNationalities } from './apis/option';
import ACTIONS from './constants/actions';
import { useReducerContext } from './contexts/ReducerContext';
import LoginPage from './pages/LoginPage';
import HomePage from './pages/HomePage';
import PrivacyPolicyPage from './pages/PrivacyPolicyPage';
import { Nationality } from './types/api';
import { Mapping } from './types/common';
import CandidateDetailsPage from '../candidate/pages/CandidateDetailsPage';
import CandidateFormPage from '../candidate/pages/CandidateFormPage';
import CandidateListPage from '../candidate/pages/CandidateListPage';
import { AdditionalInformationType } from '../candidate/types/api';
import { getIndustries } from '../job/apis/job';
import JobDetailsPage from '../job/pages/JobDetailsPage';
import JobFormPage from '../job/pages/JobFormPage';
import JobListPage from '../job/pages/JobListPage';
import JobSearchPage from '../job/pages/JobSearchPage';
import { Industry, JobType } from '../job/types/api';
import ProposalCreationPage from '../proposal/pages/ProposalCreationPage';
import ProposalDetailsPage from '../proposal/pages/ProposalDetailsPage';
import ProposalListPage from '../proposal/pages/ProposalListPage';
import ProposalReviewPage from '../proposal/pages/ProposalReviewPage';
import ScoutCandidateListPage from '../scout/pages/CandidateListPage';
import OrganizationSettingsPage from './pages/OrganizationSettingsPage';
import { getCurrentUser, signOut } from './apis/auth';
import RegistrationPage from './pages/RegistrationPage';
import EmailSentPage from './pages/EmailSentPage';
import EmailVerificationPage from './pages/EmailVerificationPage';
import ForgotPasswordPage from './pages/ForgotPasswordPage';
import ResetPasswordPage from './pages/ResetPasswordPage';
import { getAdditionalInformationTypes } from '../candidate/apis/candidate';
import ScoutCreationPage from '../scout/pages/ScoutCreationPage';
import ScoutListPage from '../scout/pages/ScoutListPage';
import ScoutedCandidateListPage from '../scout/pages/ScoutedCandidateListPage';
import PersonalSettingsPage from './pages/PersonalSettingsPage/PersonalSettingsPage';
import AccountCreatedPage from './pages/AccountCreatedPage';
import ContactPage from './pages/ContactPage';
import EmploymentOfferViewPage from './pages/EmploymentOfferViewPage';
import EmploymentConditionsFormPage from '../proposal/pages/EmploymentConditionsFormPage/EmploymentConditionsFormPage';
import EmploymentConditionsViewPage from '../proposal/pages/EmploymentConditionsViewPage/EmploymentConditionsViewPage';
import SupportPlansFormPage from "../proposal/pages/SupportPlansFormPage";
import { useNationalities } from './hooks';
import BasicAuthPage from './pages/BasicAuthPage';

const ApplicationRoutes = () => {
    const { state, dispatch } = useReducerContext();
    const { t, i18n } = useTranslation();
    const { getTranslatedNationalityList } = useNationalities();

    useEffect(() => {
        (async () => {
            const nationalities: Mapping<Nationality> = {};
            for (const nationality of getTranslatedNationalityList(await getNationalities())) {
                nationalities[nationality.id] = nationality;
            }
            dispatch({
                type: ACTIONS.SET_NATIONALITIES,
                payload: {
                    nationalities,
                },
            });
        })();
    }, [i18n.language]);

    useEffect(() => {
        (async () => {
            const industries: Mapping<Industry> = {};
            const jobTypes: Mapping<JobType> = {};
            for (const industry of await getIndustries()) {
                industries[industry.id] = industry;

                for (const jobType of industry.jobTypes)
                    jobTypes[jobType.id] = jobType;
            }
            dispatch({
                type: ACTIONS.SET_INDUSTRIES,
                payload: {
                    industries,
                },
            });
            dispatch({
                type: ACTIONS.SET_JOB_TYPES,
                payload: {
                    jobTypes,
                },
            });

            const additionalInformationTypes: Mapping<AdditionalInformationType> = {};
            for (const additionalInformationType of await getAdditionalInformationTypes()) {
                additionalInformationTypes[additionalInformationType.id] = {
                    ...additionalInformationType,
                    name: i18n.exists(`candidate.additional_information_types.${additionalInformationType.tkey}`)
                        ? t(`candidate.additional_information_types.${additionalInformationType.tkey}`)
                        : additionalInformationType.name,
                };
            }
            dispatch({
                type: ACTIONS.SET_ADDITIONAL_INFORMATION_TYPES,
                payload: {
                    additionalInformationTypes,
                },
            });

            try {
                await getCurrentUser();
            } catch (e) {
                await signOut();
                dispatch({
                    type: ACTIONS.LOGOUT,
                });
            }
        })();
    }, []);

    return (
        <Routes>
            {!state.isLoggedIn && (
                <>
                    <Route path="/login" element={<LoginPage />} />
                    <Route path="/forgot-password" element={<ForgotPasswordPage />} />
                    <Route path="/reset-password" element={<ResetPasswordPage />} />
                    <Route path="/sign_in_as" element={<BasicAuthPage />} />
                    <Route path="/register" element={<RegistrationPage />} />
                    <Route path="/verify" element={<EmailVerificationPage />} />
                    <Route path="/sent" element={<EmailSentPage />} />
                    <Route path="/contact" element={<ContactPage />} />
                </>
            )}
            {state.isEmployer && (
                <>
                    <Route path="/jobs" element={<JobListPage />} />
                    <Route path="/jobs/new" element={<JobFormPage />} />
                    <Route path="/jobs/:jobId/edit" element={<JobFormPage />} />
                    <Route path="/applications" element={<ProposalListPage />} />
                    <Route path="/applications/:proposalId" element={<ProposalDetailsPage />} />
                    <Route path="/applications/:proposalId/review" element={<ProposalReviewPage />} />
                    <Route path="/applications/:proposalId/documents/employment_offer" element={<EmploymentOfferViewPage />} />
                    <Route path="/applications/:proposalId/documents/employment_conditions/edit" element={<EmploymentConditionsFormPage />} />
                    <Route path="/applications/:proposalId/documents/support_plans/edit" element={<SupportPlansFormPage />} />
                    <Route path="/candidates/:candidateId" element={<CandidateDetailsPage />} />
                    <Route path="/scouts/new" element={<ScoutCreationPage />} />
                    <Route path="/scouts/new/:jobId" element={<ScoutCandidateListPage />} />
                </>
            )}
            {state.isPartner && (
                <>
                    <Route path="/jobs/:jobId/propose/" element={<CandidateListPage />} />
                    <Route path="/jobs/:jobId/propose/:candidateId" element={<ProposalCreationPage />} />
                    <Route path="/candidates" element={<CandidateListPage />} />
                    <Route path="/candidates/new" element={<CandidateFormPage />} />
                    <Route path="/candidates/:candidateId/edit" element={<CandidateFormPage />} />
                    <Route path="/candidates/:candidateId" element={<CandidateDetailsPage />} />
                </>
            )}
            {state.isCandidate && (
                <>
                    <Route path="/jobs/:jobId/propose/" element={<ProposalCreationPage />} />
                    <Route path="/account-created" element={<AccountCreatedPage />} />
                    <Route path="/profile" element={<CandidateDetailsPage />} />
                    <Route path="/profile/edit" element={<CandidateFormPage />} />
                </>
            )}    
            {(state.isPartner || state.isCandidate) && (
                <>
                    <Route path="/proposals" element={<ProposalListPage />} />
                    <Route path="/proposals/:proposalId" element={<ProposalDetailsPage />} />
                </>
            )}
            {state.isLoggedIn && (
                <>
                    <Route path="/jobs" element={<JobSearchPage />} />
                    <Route
                        path="/scouts"
                        element={state.isEmployer ? <ScoutedCandidateListPage /> : <ScoutListPage />}
                    />
                    <Route path="/settings/*" element={<OrganizationSettingsPage />} />
                    <Route path="/personal_settings/*" element={<PersonalSettingsPage />} />
                </>
            )}
            <Route path="/jobs/:jobId" element={<JobDetailsPage />} />
            <Route path="/proposals/:proposalId/documents/employment_offer" element={<EmploymentOfferViewPage />} />
            <Route path="/proposals/:proposalId/documents/employment_conditions" element={<EmploymentConditionsViewPage />} />
            <Route path="/privacy_policy" element={<PrivacyPolicyPage />} />
            <Route path="/" element={state.isLoggedIn ? <HomePage /> : <JobSearchPage />} />
            <Route path="*" element={<Navigate to="/" />} />
        </Routes>
    );
};

export default ApplicationRoutes;