import { useEffect } from "react";
import Page from "../components/Page";
import { useNavigate, useSearchParams } from "react-router-dom";
import { getCurrentUser, signInAs } from "../apis/auth";
import { useReducerContext } from "../contexts/ReducerContext";
import { useTranslation } from "react-i18next";
import ACTIONS from "../constants/actions";


interface BasicAuthPageProps {}


const BasicAuthPage: React.FC<BasicAuthPageProps> = () => {
  const { dispatch } = useReducerContext();
  const { t, i18n } = useTranslation();
  const [params] = useSearchParams();
  const nonce = params.get("nonce");
  const navigate = useNavigate();

  useEffect(() => {
    (async () => {
      try {
        dispatch({ type: ACTIONS.START_LOADING });

        if (!nonce) 
          throw new Error();
        
        /* TODO: 
           For some reason, unless we call getCurrentUser twice or more,
           the login will fail. This is a temporary workaround and should be fixed later. */
        const user = await signInAs(nonce);
        
        for (let i = 0; i < 3; i++) {
          try {
            await getCurrentUser();
          } catch {}
        }

        const preferredLang = user.userSetting.language?.code;

        if (preferredLang) {
          i18n.changeLanguage(preferredLang);
          localStorage.setItem("lng", preferredLang);
        }

        dispatch({
          type: ACTIONS.LOGIN,
          payload:  {
            company: user.company,
            candidate: user.candidate,
            isEmployer: !!user.company?.employer?.id,
            isPartner: !!user.company?.partner?.id,
            isCandidate: !!user.candidate?.id,
            userSetting: user.userSetting
          }
        });
        dispatch({
            type: ACTIONS.SET_PROMPT,
            payload: {
                type: 'success',
                message: t('core.login_success'),
            },
        });
      } catch {
        dispatch({
          type: ACTIONS.SET_PROMPT,
          payload: {
              type: 'warning',
              message: t('core.login_failed'),
          },
      });
      } finally {
        dispatch({ type: ACTIONS.STOP_LOADING });
        navigate("/");
      }
    })();
  }, []);

  //Show a blank page (this is intentional)
  return <Page />;
};

export default BasicAuthPage;